module controllers {
    export module logistics {

        interface IVesselScheduleCtrlScope extends ng.IScope {
        }

        interface IVesselScheduleParams extends ng.ui.IStateParamsService {
        }

        export class vesselScheduleListCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "vesselService",
                "$state",
                "$stateParams",
                "$transitions",
                "vesselScheduleService",
                'countryService',
            ];

            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.logistics.VesselScheduleDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            vesselScheduleSearch: interfaces.logistics.IVesselScheduleSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string = "vessel schedule list";
            sortName: string;
            sortDirection: string;

            filterNames: string[] = ["VesselCallSign","VesselName","VesselOperatorCodeName","TerminalCodeAndName","VoyageNumber","DateOfArrival","TimeOfBerthing","StorageEnd","StackOpening","StackClosing","StackStatus","DateOfDeparture"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];            

            selectedCountry :interfaces.applicationcore.IDropdownModel;
            
            constructor(private $scope: IVesselScheduleCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private vesselService: interfaces.master.IVesselService,
                private $state: ng.ui.IStateService,
                private $stateParams: IVesselScheduleParams,
                private $transitions: ng.ui.core.ITransition,
                private vesselScheduleService: interfaces.logistics.IVesselScheduleService,
                public countryService: interfaces.applicationcore.ICountryService,
            ) {

                var ArrivalDate =  moment.utc();
                ArrivalDate = ArrivalDate.add(-31,"day");
                
                this.vesselScheduleSearch = {
                    ArrivalDateFrom: ArrivalDate
                };

                this.loadEntityDefaultCountry();                
                
                this.myHook = $transitions.onSuccess({
                    to: 'auth.VesselSailingSchedule',
                    from: 'auth.VesselSailingSchedule.**'
                }, () => {
                    this.loadVesselSchedules();

                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadTerminals(searchText: string){
                return this.vesselScheduleService.GetTerminalsForDropDownByCountry(this.selectedCountry.Id,searchText).query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        this.vesselScheduleSearch.Terminal = null;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadEntityDefaultCountry(){
                return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                    this.entityService.getEntityCountry().get({ entityId: resultEntity.Id }, (result: interfaces.applicationcore.ICountry) => {
                        this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                            Id: result.Id,
                            Code: result.Code,
                            Description: result.Description,
                            Display: result.Code + " - " + result.Description,
                            Selected: true
                        };

                        this.DoSearch();
                    });
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            countryChanged(model: interfaces.applicationcore.IDropdownModel){
                this.selectedCountry = model;
                this.loadTerminals("");
            }

            
            loadVesselSchedules(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'vesselScheduleList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.vesselScheduleSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        searchObject.filters.push({ Name: "Country", Value: this.selectedCountry.Id });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.vesselScheduleService.getList().query(params, (result: Array<interfaces.logistics.VesselScheduleDisplay>) => {
                            this.gvwVesselSchedules.data = result;
                            if (result[0]) {
                                this.gvwVesselSchedules.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwVesselSchedules.totalItems = 0;
                            }                            
                            this.$timeout(() => {
                                if (this.gridApi) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }
// ["VesselCallSign","VesselName","VesselOperatorCodeName","TerminalCodeAndName","VoyageNumber","DateOfArrival","TimeofBerthing","StorageEnd","StackOpening","StackClosing","StackStatus","DateOfDeparture"];
            public gvwVesselSchedules: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.UpdatedWithinLastDay}" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.vesselScheduleListCtrl.editVesselSchedule(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, 
                {
                    name: "VesselCallSign",
                    displayName: "Vessel Call Sign",
                    field: "VesselCallSign",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, 
                {
                    name: "VesselName",
                    displayName: "Vessel Name",
                    field: "VesselName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, 
                {
                    name: "VesselOperatorCodeName",
                    displayName: "Vessel Operator Name",
                    field: "VesselOperatorCodeName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },       
                {
                    name: "TerminalCodeAndName",
                    displayName: "Terminal",
                    field: "TerminalCodeAndName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },       
                {
                    name: "VoyageNumber",
                    displayName: "Voyage Number",
                    field: "VoyageNumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "DateOfArrival",
                    displayName: "Arrival",
                    field: "DateOfArrival",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                            </div>`,
                }, {
                    name: "TimeOfBerthing",
                    displayName: "Berthing",
                    field: "TimeOfBerthing",
                    width: 125,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                    </div>`,
                }
                , {
                    name: "StorageEnd",
                    displayName: "Storage End",
                    field: "StorageEnd",
                    width: 125,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                    </div>`,
                }, {
                    name: "StackOpening",
                    displayName: "Stack Opening",
                    field: "StackOpening",
                    width: 125,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                    </div>`,
                }  , {
                    name: "StackClosing",
                    displayName: "Stack Closing",
                    field: "StackClosing",
                    width: 125,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                    </div>`,
                } , 
                {
                    name: "StackStatus",
                    displayName: "Stack Status",
                    field: "StackStatus",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "DateOfDeparture",
                    displayName: "Departure",
                    field: "DateOfDeparture",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                            </div>`,
                }
                ]                
            }


            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.vesselListFilterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.vesselListSortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }
            
            vesselListSortChange(gridApi) {
                this.DoSearch();
            }
            
            vesselListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            ListPageChange(newPage, pageSize) {

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'vesselScheduleList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.vesselScheduleSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        searchObject.filters.push({ Name: "Country", Value: this.selectedCountry.Id });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.vesselScheduleService.getList().query(params, (result: Array<interfaces.logistics.VesselScheduleDisplay>) => {
                            var currentDirtyRows: Array<interfaces.logistics.VesselScheduleDisplay> = [];

                            /*_.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.master.IVesselDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });*/

                            this.gvwVesselSchedules.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwVesselSchedules.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwVesselSchedules.totalItems = 0;
                            }

                                this.$timeout(() => {
                                if (this.gridApi) {
                                    //this.getFilters();
                                    //this.setFilters();
                                }
                            });
                            
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.vesselScheduleSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.vesselScheduleService.getVesselScheduleListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwVesselSchedules.data = [];
                    this.gvwVesselSchedules.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }  

            
            getFilters() {
                _.forEach(this.filterNames, (name: string) => {

                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            editVesselSchedule(vshId: number){
                this.$state.go("auth.VesselSailingSchedule.Update", { vshId: vshId });
            }

            createNewVesselSchedule(){
                this.$state.go("auth.VesselSailingSchedule.Update", { vshId: 0 });
            }


            UploadFromExcel(){
                this.$uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    keyboard: false,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Upload from Excel <i class="fa fa-question-circle" uib-tooltip='Use this option to add or updatevessel schedule detail using an Excel Spreadsheet. The format of the spreadsheet is the same as the Download to Excel functionality.' tooltip-placement="bottom"></i></h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="vesselschedule.excelupload">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">

                                        <div class="row">
                                            <div class="row vertical-align">
                                                <div class="col-md-8">
                                                    <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit">
                                                        <span>Select File</span>
                                                        <input name="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit" class="upload" type="file" id="docFileToUpload" ng-model="uploadExcelCtrl.repositoryFile" base-sixty-four-input accept=".xlsx" />
                                                    </label>
                                                    <span>{{uploadExcelCtrl.repositoryFile.filename}}</span>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!uploadExcelCtrl.repositoryFile || uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.upload()">Submit</button>
                                        <button class="btn btn-info" type="button" ng-disabled="uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class uploadExcelCtrl {

                        messages: interfaces.applicationcore.IMessage[];

                    
                        disableSubmit: boolean = false;
                        repositoryFile: interfaces.applicationcore.IRepositoryFile;

                        vesselScheduleExcelData: interfaces.logistics.VesselScheduleExcelData;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private vesselScheduleService: interfaces.logistics.IVesselScheduleService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            public bsLoadingOverlayService,
                            private comId : number
                        ) {
                        
                        }

                 

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        upload() {
                             this.disableSubmit = true;
                             this.vesselScheduleExcelData = <interfaces.logistics.VesselScheduleExcelData>{
                                      repositoryFile:this.repositoryFile
                             };

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'vesselschedule.excelupload'
                            },
                                () => {

                                    this.vesselScheduleService.uploadFromExcel().save(this.vesselScheduleExcelData, (result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage) {
                                            this.$uibModalInstance.close(true);
                                        }

                                        this.$uibModalInstance.close(true);
                                    }, (errorResult) => {
                                        this.generalService.displayMessageHandler(errorResult.data);

                                        if (errorResult.data.Messages) {
                                            this.messages = [];
                                        }

                                        angular.forEach(errorResult.data.Messages, (o) => {
                                            angular.forEach(o.MessageItems, (n) => {
                                                this.messages.push(n);
                                            });
                                        });
                                        }).$promise;

                                });
                        }
                    },
                    controllerAs: "uploadExcelCtrl",
                    resolve: { comId : this.$state.params['comId']
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadVesselSchedules();
                    }
                });
            }


        }

         angular.module("app").controller("vesselScheduleListCtrl", controllers.logistics.vesselScheduleListCtrl);    
    }
}